import React from 'react';
import { AppBar, Toolbar, Typography, Box } from '@mui/material';
import { Link } from 'react-router-dom';
import logo from '../assets/logo-bigger.png'; // Adjust path as needed

const Navbar: React.FC = () => {
    return (
        <AppBar position="static" sx={{ backgroundColor: 'black', minHeight: '70px' }}>
            <Toolbar sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', minHeight: '70px', px: 2 }}>
                {/* Logo on the left with Link */}
                <Box sx={{ display: 'flex', alignItems: 'center', flex: 1, paddingTop: '4px' }}>  {/* Add top padding here */}
                    <Link to="/" style={{ textDecoration: 'none' }}>
                        <img
                            src={logo}
                            alt="Ravers House Logo"
                            style={{ height: '60px', maxHeight: '100%', marginRight: '20px' }} // Adjust height and margin
                        />
                    </Link>
                </Box>

                {/* Centered Navigation Links with Typography */}
                <Box sx={{ display: 'flex', justifyContent: 'center', flex: 2 }}>
                    <Link to="/events" style={{ textDecoration: 'none' }}>
                        <Typography variant="button" sx={{ color: 'text.primary', textTransform: 'uppercase', fontWeight: 'bold', mx: 2 }}>
                            Events
                        </Typography>
                    </Link>
                    <Link to="/shop" style={{ textDecoration: 'none' }}>
                        <Typography variant="button" sx={{ color: 'text.primary', textTransform: 'uppercase', fontWeight: 'bold', mx: 2 }}>
                            Shop
                        </Typography>
                    </Link>
                    <Link to="/contact" style={{ textDecoration: 'none' }}>
                        <Typography variant="button" sx={{ color: 'text.primary', textTransform: 'uppercase', fontWeight: 'bold', mx: 2 }}>
                            Contact
                        </Typography>
                    </Link>
                </Box>

                {/* Empty Box to balance layout */}
                <Box sx={{ flex: 1 }} />
            </Toolbar>
        </AppBar>
    );
};

export default Navbar;

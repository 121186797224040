import React, { useEffect, useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { config } from '../config';

const AdminDashboard: React.FC = () => {
    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
    const navigate = useNavigate();

    useEffect(() => {
        const token = localStorage.getItem('token');  // Get JWT token from localStorage

        if (!token) {
            navigate('/login');  // Redirect to login if no token is found
            return;
        }

        // Verify token with backend
        const checkAuth = async () => {
            try {
                const response = await axios.get(config.apiBaseUrl + '/api/admin/dashboard', {
                    headers: { Authorization: `Bearer ${token}` },  // Send token in Authorization header
                });

                if (response.status === 200) {
                    setIsAuthenticated(true);  // User is authenticated
                }
            } catch (err) {
                console.log('Token is invalid or expired:', err);
                navigate('/login');  // Redirect to login if token is invalid
            }
        };

        checkAuth();
    }, [navigate]);

    const handleLogout = () => {
        localStorage.removeItem('token');  // Clear the token from storage
        navigate('/admin');  // Redirect to login
    };

    if (!isAuthenticated) {
        return <Typography>Loading...</Typography>;  // Show loading while verifying authentication
    }

    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minHeight: 'calc(100vh - 140px)', // Adjust based on navbar/footer heights
                width: '100%', // Ensure full-width stretch
                padding: '40px 0', // Padding on top and bottom for a bit of extra height
                backgroundColor: 'rgba(0, 0, 0, 0.8)', // Semi-transparent background for overlay
            }}
        >
            <Box
                sx={{
                    width: '100%', // Extend the box to the full width of the viewport
                    maxWidth: '600px', // Limit the max width to ensure it doesn't stretch too much
                    padding: '60px 40px', // Padding for top/bottom and left/right
                    backgroundColor: '#131313', // Dark gray background for the overlay box
                    borderRadius: '10px', // Rounded corners for the box
                    boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.3)', // Box shadow for some depth
                    textAlign: 'center', // Center-align text and content
                }}
            >
                <Typography
                    variant="h3"
                    sx={{
                        color: 'white', // White text color
                        textTransform: 'uppercase', // Uppercase text
                        fontWeight: 'bold', // Bold font weight
                        marginBottom: '40px', // Space below the title
                    }}
                >
                    Admin Dashboard
                </Typography>

                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column', // Stack buttons vertically
                        alignItems: 'center', // Center buttons
                        gap: '20px', // Space between buttons
                    }}
                >
                    {/* Buttons with same length based on the longest one */}
                    <Button
                        variant="outlined"
                        sx={{
                            minWidth: '200px', // Minimum width based on the longest button text
                            padding: '12px 24px', // Button padding
                            border: '2px solid white', // White border
                            color: 'white', // White text
                            '&:hover': {
                                backgroundColor: '#444', // Darker background on hover
                                borderColor: 'white', // Keep the white border on hover
                            },
                        }}
                        onClick={() => navigate('/admin/subscribers')}
                    >
                        Subscribers List
                    </Button>

                    <Button
                        variant="outlined"
                        sx={{
                            minWidth: '200px',
                            padding: '12px 24px',
                            border: '2px solid white',
                            color: 'white',
                            '&:hover': {
                                backgroundColor: '#444',
                                borderColor: 'white',
                            },
                        }}
                        onClick={() => navigate('/admin/events')}
                    >
                        Manage Events
                    </Button>
                    <Button
                        variant="outlined"
                        color="error"
                        sx={{
                            minWidth: '200px',
                            padding: '12px 24px',
                            border: '2px solid white',
                            color: 'white',
                            '&:hover': {
                                backgroundColor: 'red',
                                borderColor: 'white',
                            },
                        }}
                        onClick={handleLogout}
                    >
                        Logout
                    </Button>
                </Box>
            </Box>
        </Box>
    );
};

export default AdminDashboard;

import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useEvents } from '../hooks/useEvents';

const Events: React.FC = () => {
    const { events, loading } = useEvents();
    const navigate = useNavigate();

    if (loading) {
        return <Typography>Loading events...</Typography>;
    }

    return (
        <Box
            sx={{
                maxWidth: '1200px',
                margin: '0 auto',
                padding: '40px 20px',
            }}
        >
            {events.map((event) => (
                <Box
                    key={event.id}
                    sx={{
                        display: 'flex',
                        flexDirection: { xs: 'column', md: 'row' },
                        alignItems: { xs: 'flex-start', md: 'center' },
                        marginBottom: '40px',
                        backgroundColor: 'background.paper',
                        borderRadius: '8px',
                        overflow: 'hidden',
                        transition: 'background-color 0.3s ease',
                        '&:hover': {
                            backgroundColor: '#000000',
                        },
                    }}
                >
                    <Box
                        component="img"
                        src={`data:image/png;base64,${event.posterimg}`}
                        alt={event.name}
                        sx={{
                            width: { xs: '100%', md: '40%' },
                            height: 'auto',
                            objectFit: 'cover',
                        }}
                    />
                    <Box
                        sx={{
                            padding: '20px',
                            flex: 1,
                            color: 'text.primary',
                            textAlign: { xs: 'left', md: 'left' },
                            transition: 'color 0.3s ease',
                        }}
                    >
                        <Typography variant="h2" sx={{ mb: 2, fontSize: { xs: '1.5rem', md: '2rem' } }}>
                            {event.name}
                        </Typography>
                        <Typography variant="body1" sx={{ mb: 2, color: 'text.secondary', fontSize: { xs: '1rem', md: '1.25rem' } }}>
                            {event.city} - {event.date}
                        </Typography>
                        <Typography variant="body2" sx={{ mb: 4, fontSize: { xs: '0.875rem', md: '1rem' } }}>
                            {event.description}
                        </Typography>
                        <Button
                            variant="outlined"
                            sx={{
                                backgroundColor: 'black',
                                color: 'white',
                                borderColor: 'white',
                                textTransform: 'uppercase',
                                '&:hover': {
                                    backgroundColor: 'white',
                                    color: 'black',
                                    borderColor: 'black',
                                },
                            }}
                            onClick={() => navigate(`/events/${event.id}`)}
                        >
                            More Info
                        </Button>
                    </Box>
                </Box>
            ))}
        </Box>
    );
};

export default Events;

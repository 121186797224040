import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const ShopComingSoon: React.FC = () => {
    const navigate = useNavigate();

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                minHeight: '100vh',  // Full screen height
                backgroundColor: 'background.default',  // Black background from theme
                textAlign: 'center',  // Center the text and content
                padding: '0 20px',  // Some padding for mobile responsiveness
            }}
        >
            {/* Main Message */}
            <Typography
                variant="h1"
                sx={{
                    color: 'text.primary',  // Use white text color
                    fontWeight: 'bold',
                    mb: 4,  // Margin bottom for spacing
                    textTransform: 'uppercase',  // Uppercase for impact
                }}
            >
                Coming Soon
            </Typography>

            {/* Sub Message */}
            <Typography
                variant="h6"
                sx={{
                    color: 'text.secondary',  // Use secondary text color (lighter gray)
                    mb: 6,  // Spacing below the sub-message
                }}
            >
                The shop is under construction. Stay tuned for updates!
            </Typography>

            {/* Optional Back to Home Button */}
            <Button
                variant="outlined"
                sx={{
                    color: 'white',  // White text
                    borderColor: 'white',  // White border
                    textTransform: 'uppercase',  // Keep button text uppercase
                    '&:hover': {
                        backgroundColor: 'white',  // White background on hover
                        color: 'black',  // Black text on hover
                    },
                }}
                onClick={() => navigate('/')}  // Redirect to home on click
            >
                Back to Home
            </Button>
        </Box>
    );
};

export default ShopComingSoon;

import React, { useState, useEffect } from 'react';
import { Box, Button, TextField, Typography, CircularProgress } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { useEvents } from '../hooks/useEvents';

const EventForm: React.FC = () => {
    const { eventId } = useParams<{ eventId: string }>();
    const { addEvent, updateEvent, events, loading: eventsLoading } = useEvents();
    const [event, setEvent] = useState({
        name: '',
        date: '',
        city: '',
        location: '',
        posterImg: '',
        description: '',
        ticketsUrl: '',
        lineup: '',
    });
    const [loading, setLoading] = useState(false); // For form submission
    const navigate = useNavigate();

    useEffect(() => {
        const fetchEventData = () => {
            if (eventId && events.length > 0) { // Check if events have been loaded
                const existingEvent = events.find(event => event.id.toString() === eventId);
                if (existingEvent) {
                    setEvent({
                        name: existingEvent.name,
                        date: existingEvent.date,
                        city: existingEvent.city,
                        location: existingEvent.location,
                        posterImg: existingEvent.posterimg,
                        description: existingEvent.description,
                        ticketsUrl: existingEvent.ticketsurl,
                        lineup: existingEvent.lineup.join(', '),
                    });
                }
            }
        };

        fetchEventData();
    }, [eventId, events]);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setEvent({ ...event, [name]: value });
    };

    const handleImageUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                const base64String = reader.result as string;
                const base64Data = base64String.replace(/^data:image\/[a-z]+;base64,/, '');
                setEvent({ ...event, posterImg: base64Data });
            };
            reader.readAsDataURL(file);
        }
    };

    const handleSubmit = async () => {
        setLoading(true);
        const formattedEvent = {
            ...event,
            lineup: event.lineup.split(',').map(item => item.trim()),
        };

        try {
            if (eventId) {
                await updateEvent(Number(eventId), formattedEvent);
            } else {
                await addEvent(formattedEvent);
            }
            navigate('/admin/events');
        } catch (error) {
            console.error('Error submitting event:', error);
        } finally {
            setLoading(false);
        }
    };

    if (eventsLoading || (eventId && !events.length)) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <CircularProgress />
            </Box>
        );
    }

    return (
        <Box sx={{ padding: '40px', maxWidth: '600px', margin: '0 auto' }}>
            <Typography variant="h4" sx={{ mb: 3 }}>
                {eventId ? 'Edit Event' : 'Add Event'}
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
                <TextField label="Name" name="name" value={event.name} onChange={handleInputChange} fullWidth />
                <TextField label="Date" name="date" value={event.date} onChange={handleInputChange} fullWidth />
                <TextField label="City" name="city" value={event.city} onChange={handleInputChange} fullWidth />
                <TextField label="Location" name="location" value={event.location} onChange={handleInputChange} fullWidth />

                <Box>
                    <Typography variant="subtitle1">Poster Image</Typography>
                    {event.posterImg && (
                        <Box sx={{ mb: 2 }}>
                            <img src={`data:image/png;base64,${event.posterImg}`} alt="Event Poster" style={{ maxWidth: '100%', height: 'auto' }} />
                        </Box>
                    )}
                    <Button variant="contained" component="label">
                        Upload Image
                        <input type="file" accept="image/*" hidden onChange={handleImageUpload} />
                    </Button>
                </Box>

                <TextField label="Description" name="description" value={event.description} onChange={handleInputChange} fullWidth multiline rows={4} />
                <TextField label="Tickets URL" name="ticketsUrl" value={event.ticketsUrl} onChange={handleInputChange} fullWidth />
                <TextField label="Lineup (comma-separated)" name="lineup" value={event.lineup} onChange={handleInputChange} fullWidth />

                <Button variant="contained" color="primary" onClick={handleSubmit} disabled={loading}>
                    {loading ? <CircularProgress size={24} /> : (eventId ? 'Update Event' : 'Add Event')}
                </Button>
            </Box>
        </Box>
    );
};

export default EventForm;

import React, { useEffect, useState } from 'react';
import { Box, Typography, CircularProgress, Alert, Table, TableBody, TableCell, TableRow, Paper } from '@mui/material';
import { config } from "../config";

const StatusPage: React.FC = () => {
    const [status, setStatus] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        const fetchStatus = async () => {
            try {
                const response = await fetch(`${config.apiBaseUrl}/api/status`);
                if (response.ok) {
                    const data = await response.json();
                    setStatus(JSON.stringify(data, null, 2));
                } else {
                    setError('Failed to fetch backend status');
                }
            } catch (err) {
                setError('Error fetching backend status');
            } finally {
                setLoading(false);
            }
        };

        fetchStatus();
    }, [config.apiBaseUrl]);

    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
                backgroundColor: '#000',
                padding: '20px',
            }}
        >
            <Paper
                elevation={5}
                sx={{
                    padding: '30px',
                    maxWidth: '600px',
                    width: '100%',
                    backgroundColor: '#1a1a1a',
                    borderRadius: '8px',
                    color: '#fff',
                }}
            >
                <Typography variant="h4" sx={{ mb: 4, textAlign: 'center', color: '#fff' }}>
                    Backend Status
                </Typography>
                {loading ? (
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <CircularProgress />
                    </Box>
                ) : error ? (
                    <Alert severity="error">{error}</Alert>
                ) : (
                    <Table
                        sx={{
                            width: '100%',
                            backgroundColor: '#2a2a2a',
                            borderRadius: '8px',
                            border: '1px solid #333',
                        }}
                    >
                        <TableBody>
                            <TableRow>
                                <TableCell sx={{ color: '#fff', fontWeight: 'bold' }}>Backend</TableCell>
                                <TableCell sx={{ color: '#4caf50' }}>OK</TableCell>
                            </TableRow>
                            {/* Add more rows as needed for other statuses */}
                        </TableBody>
                    </Table>
                )}
            </Paper>
        </Box>
    );
};

export default StatusPage;

import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Home from './pages/Home';
import Events from './pages/Events';
import Contact from './pages/Contact';
import Login from "./pages/Login";
import StatusPage from "./pages/StatusPage";
import AdminDashboard from "./pages/AdminDashboard";
import Shop from "./pages/Shop";
import EventDetails from "./pages/EventDetails";
import Subscribers from "./pages/Subscribers";
import EventForm from "./pages/EventForm";
import ManageEvents from "./pages/ManageEvents";

function App() {
    return (
        <>
            <Navbar />
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/events" element={<Events />} />
                <Route path="/events/:eventId" element={<EventDetails />} />  {/* Event details route */}
                <Route path="/contact" element={<Contact />} />
                <Route path="/login" element={<Login />} />
                <Route path="/admin/dashboard" element={<AdminDashboard />} />
                <Route path="/admin/subscribers" element={<Subscribers />} />
                <Route path="/admin/events" element={<ManageEvents />} />
                <Route path="/admin/events/new" element={<EventForm />} />
                <Route path="/admin/events/edit/:eventId" element={<EventForm />} />
                <Route path="/status" element={<StatusPage />} />
                <Route path="/shop" element={<Shop />} />
            </Routes>
            <Footer />
        </>
    );
}

export default App;

import React, { useState } from 'react';
import { Box, Typography, TextField, Button, Container } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import {config} from "../config";

const Newsletter: React.FC = () => {
    const theme = useTheme();
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');

    const handleSubscribe = async () => {
        // Reset messages
        setMessage('');
        setError('');

        if (!email) {
            setError('Please enter a valid email.');
            return;
        }

        try {
            const response = await fetch(config.apiBaseUrl + '/api/newsletter/subscribe', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email }),
            });

            const result = await response.json();

            if (response.ok) {
                setMessage('Successfully subscribed!');
                setEmail('');
            } else {
                setError(result.error || 'An error occurred while subscribing.');
            }
        } catch (error) {
            setError('Failed to subscribe. Please try again later.');
        }
    };

    return (
        <Container maxWidth="sm" sx={{ textAlign: 'center', py: 5 }}>
            <Typography variant="h4" sx={{ mb: 2, textTransform: 'uppercase', fontWeight: 'bold' }}>
                Stay Updated!
            </Typography>
            <Typography variant="body1" sx={{ mb: 4, color: theme.palette.text.primary }}>
                Subscribe to our newsletter for the latest events, news, and early access!
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 2 }}>
                <TextField
                    variant="outlined"
                    placeholder="Enter your email"
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    InputProps={{
                        style: {
                            color: theme.palette.text.primary, // Text color inside the input
                        },
                    }}
                    sx={{
                        flex: 1,
                        bgcolor: theme.palette.background.paper, // Using theme for background
                        borderRadius: 1,
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                borderColor: theme.palette.divider, // Border color from theme
                            },
                            '&:hover fieldset': {
                                borderColor: theme.palette.primary.main, // Hover border color
                            },
                        },
                    }}
                />
                <Button
                    variant="contained"
                    onClick={handleSubscribe}
                    sx={{
                        bgcolor: theme.palette.background.paper, // Using theme for button background
                        color: theme.palette.text.primary, // Using theme for button text color
                        height: '56px',
                        '&:hover': {
                            bgcolor: theme.palette.grey[300], // Hover color using theme
                        },
                    }}
                >
                    Subscribe
                </Button>
            </Box>

            {message && (
                <Typography variant="body2" sx={{ mt: 2, color: theme.palette.success.main }}>
                    {message}
                </Typography>
            )}

            {error && (
                <Typography variant="body2" sx={{ mt: 2, color: theme.palette.error.main }}>
                    {error}
                </Typography>
            )}
        </Container>
    );
};

export default Newsletter;

import React from 'react';
import Intro from '../components/Intro';
import EventsCarousel from '../components/EventsCarousel';
import Newsletter from "../components/Nesletter";

const Home: React.FC = () => {
    return (
        <>
            <Intro />
            <EventsCarousel />
            <Newsletter />
        </>
    );
};

export default Home;

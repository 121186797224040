export const config = {
    apiBaseUrl: process.env.BACKEND_URL || 'https://backend-806836304792.europe-west1.run.app',
    appName: 'Ravers House',
    appDescription: 'Setting residence across the globe to initiate connection through music.',
    social_media: {
        instagram: "https://www.instagram.com/ravers.house/",
        facebook: "https://www.facebook.com/ravers.house",
        tiktok: "https://tiktok.com/@ravers.house"
    }
};

import React from 'react';
import { useParams } from 'react-router-dom';
import { Box, Button, Typography, Chip } from '@mui/material';
import { useEvents } from '../hooks/useEvents';
import { useNavigate } from 'react-router-dom';

const EventDetails: React.FC = () => {
    const { eventId } = useParams<{ eventId: string }>();
    const { events, loading } = useEvents();
    const event = events.find((e) => e.id.toString() === eventId);
    const navigate = useNavigate();

    if (loading) {
        return <Typography variant="h5">Loading event details...</Typography>;
    }

    if (!event) {
        return <Typography variant="h5">Event not found</Typography>;
    }

    return (
        <Box
            sx={{
                maxWidth: '1200px',
                margin: '0 auto',
                padding: '40px 20px',
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: { xs: 'column', md: 'row' },
                    alignItems: { xs: 'center', md: 'flex-start' },
                    marginBottom: '40px',
                    gap: '20px',
                }}
            >
                <Box
                    component="img"
                    src={`data:image/png;base64,${event.posterimg}`}
                    alt={event.name}
                    sx={{
                        width: { xs: '100%', md: '40%' },
                        height: 'auto',
                        objectFit: 'cover',
                    }}
                />
                <Box
                    sx={{
                        flex: 1,
                        width: { xs: '100%', md: '60%' },
                        color: 'text.primary',
                        textAlign: { xs: 'center', md: 'left' },
                    }}
                >
                    <Typography variant="h3" sx={{ mb: 4, fontWeight: 700 }}>
                        {event.name}
                    </Typography>
                    <Typography variant="body1" sx={{ fontWeight: 'bold', mb: 1 }}>
                        Date
                    </Typography>
                    <Typography variant="body1" sx={{ mb: 3 }}>
                        {event.date}
                    </Typography>
                    <Typography variant="body1" sx={{ fontWeight: 'bold', mb: 1 }}>
                        Location
                    </Typography>
                    <Typography variant="body1" sx={{ mb: 3 }}>
                        {event.city}
                    </Typography>
                    <Typography variant="body1" sx={{ fontWeight: 'bold', mb: 1 }}>
                        Description
                    </Typography>
                    <Typography variant="body2" sx={{ mb: 3 }}>
                        {event.description}
                    </Typography>
                    <Typography variant="body1" sx={{ fontWeight: 'bold', mb: 1 }}>
                        Lineup
                    </Typography>
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mb: 3, justifyContent: { xs: 'center', md: 'flex-start' } }}>
                        {event.lineup.map((artist, index) => (
                            <Chip key={index} label={artist} sx={{ fontSize: '1rem', backgroundColor: '#1d1d1d', color: 'white' }} />
                        ))}
                    </Box>
                    <Button
                        variant="outlined"
                        sx={{
                            backgroundColor: 'black',
                            color: 'white',
                            borderColor: 'white',
                            padding: '10px 20px',
                            textTransform: 'uppercase',
                            fontWeight: 'bold',
                            '&:hover': {
                                backgroundColor: 'white',
                                color: 'black',
                                borderColor: 'black',
                            },
                        }}
                        onClick={() => navigate(event.ticketsurl)}
                    >
                        Buy Tickets
                    </Button>
                </Box>
            </Box>
        </Box>
    );
};

export default EventDetails;

import React, { useState } from 'react';
import { Box, Button, Typography, Card, CardActionArea, useMediaQuery } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useNavigate } from 'react-router-dom';
import { useEvents } from '../hooks/useEvents';

const EventsCarousel: React.FC = () => {
    const [currentSlide, setCurrentSlide] = useState(0);
    const { events, loading } = useEvents();
    const navigate = useNavigate();

    const isLargeScreen = useMediaQuery('(min-width:1200px)');
    const isMediumScreen = useMediaQuery('(min-width:900px)');
    const isSmallScreen = useMediaQuery('(min-width:600px)');
    const visibleItems = isLargeScreen ? 4 : isMediumScreen ? 3 : isSmallScreen ? 2 : 1;

    const handleNext = () => {
        setCurrentSlide((prev) => (prev + 1) % events.length);
    };

    const handlePrev = () => {
        setCurrentSlide((prev) => (prev - 1 + events.length) % events.length);
    };

    const handleEventClick = (eventId: number) => {
        navigate(`/events/${eventId.toString()}`);
    };

    if (loading) {
        return <Typography>Loading events...</Typography>;
    }

    return (
        <Box sx={{ position: 'relative', width: '100%', margin: '0 auto', overflow: 'hidden', py: 4 }}>
            <Typography variant="h4" sx={{ textAlign: 'center', mb: 4, fontWeight: 'bold' }}>
                Events
            </Typography>

            <Button onClick={handlePrev} sx={{ position: 'absolute', top: '50%', left: '16px', transform: 'translateY(-50%)', color: 'white', zIndex: 1 }}>
                <ArrowBackIosIcon />
            </Button>
            <Button onClick={handleNext} sx={{ position: 'absolute', top: '50%', right: '16px', transform: 'translateY(-50%)', color: 'white', zIndex: 1 }}>
                <ArrowForwardIosIcon />
            </Button>

            <Box sx={{ display: 'flex', transition: 'transform 0.5s ease', transform: `translateX(-${currentSlide * (100 / visibleItems)}%)` }}>
                {events.map((event) => (
                    <Box
                        key={event.id}
                        sx={{
                            minWidth: `${100 / visibleItems}%`,
                            flex: '0 0 auto',
                            px: 2,
                        }}
                    >
                        <Card sx={{ position: 'relative', width: '100%', paddingTop: '100%', borderRadius: '8px', overflow: 'hidden' }}>
                            <CardActionArea
                                sx={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0 }}
                                onClick={() => handleEventClick(event.id)}
                            >
                                <img
                                    src={`data:image/png;base64,${event.posterimg}`}
                                    alt={event.name}
                                    style={{
                                        width: '100%',
                                        height: '100%',
                                        objectFit: 'cover',
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                    }}
                                />
                                <Box
                                    sx={{
                                        position: 'absolute',
                                        bottom: 0,
                                        left: 0,
                                        right: 0,
                                        height: '30%',
                                        backgroundColor: 'rgba(0, 0, 0, 0.7)',
                                        color: 'white',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        px: 1,
                                        py: 1.5,
                                    }}
                                >
                                    <Typography variant="h6" sx={{ fontWeight: 'bold', fontSize: '1.2rem' }}>{event.name}</Typography>
                                    <Typography variant="body2">{event.city}</Typography>
                                    <Typography variant="body2">{event.date}</Typography>
                                </Box>
                            </CardActionArea>
                        </Card>
                    </Box>
                ))}
            </Box>
        </Box>
    );
};

export default EventsCarousel;

import React from 'react';
import { Box, Typography, Link, Container } from '@mui/material';
import { Instagram, Facebook } from '@mui/icons-material';
import {config} from "../config";

const Footer: React.FC = () => {
    return (
        <Box sx={{ backgroundColor: 'background.paper', py: 4, textAlign: 'center', color: 'text.primary', mt: 5 }}>
            <Container>
                <Typography variant="body1" sx={{ mb: 2, textTransform: 'uppercase', fontWeight: 'bold' }}>
                    Find us on Social Media!
                </Typography>
                <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center', gap: 2 }}>
                    <Link href={config.social_media.instagram} sx={{ color: 'text.primary' }}>
                        <Instagram fontSize="large" />
                    </Link>
                    <Link href={config.social_media.facebook} sx={{ color: 'text.primary' }}>
                        <Facebook fontSize="large" />
                    </Link>
                </Box>
            </Container>
        </Box>
    );
};

export default Footer;

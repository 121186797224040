import React, { useEffect, useState } from 'react';
import { Box, Typography, Table, TableBody, TableCell, TableHead, TableRow, CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { config } from '../config';

interface Subscriber {
    id: number;
    email: string;
    subscribed_at: string;
}

const Subscribers: React.FC = () => {
    const [subscribers, setSubscribers] = useState<Subscriber[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string>('');
    const navigate = useNavigate();

    useEffect(() => {
        const token = localStorage.getItem('token');  // Get JWT token from localStorage

        if (!token) {
            navigate('/login');  // Redirect to login if no token is found
            return;
        }

        const fetchSubscribers = async () => {
            try {
                const response = await axios.get(config.apiBaseUrl + '/api/subscribers', {
                    headers: { Authorization: `Bearer ${token}` },
                });

                if (response.status === 200) {
                    setSubscribers(response.data);
                } else {
                    setError('Failed to fetch subscribers.');
                }
            } catch (err) {
                setError('Failed to fetch subscribers.');
            } finally {
                setLoading(false);
            }
        };

        fetchSubscribers();
    }, [navigate]);

    if (loading) {
        return <CircularProgress />;
    }

    if (error) {
        return <Typography color="error">{error}</Typography>;
    }

    return (
        <Box
            sx={{
                minHeight: '100vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: '#000',  // Black background
            }}
        >
            <Box
                sx={{
                    width: '100%',
                    maxWidth: '600px',  // Control the maximum width of the box
                    backgroundColor: '#131313',  // Dark background similar to Contact Us page
                    padding: '40px',
                    borderRadius: '10px',  // Rounded corners
                    boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.3)',  // Add shadow for depth
                    textAlign: 'center',
                }}
            >
                <Typography
                    variant="h4"
                    sx={{
                        color: '#fff',  // White text color
                        textTransform: 'uppercase',
                        fontWeight: 'bold',
                        marginBottom: '20px',
                    }}
                >
                    Subscribers List
                </Typography>

                {subscribers.length === 0 ? (
                    <Typography color="white">No subscribers found.</Typography>
                ) : (
                    <Table sx={{ backgroundColor: '#131313' }}>
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ color: '#fff', borderBottom: '1px solid #444' }}>ID</TableCell>
                                <TableCell sx={{ color: '#fff', borderBottom: '1px solid #444' }}>Email</TableCell>
                                <TableCell sx={{ color: '#fff', borderBottom: '1px solid #444' }}>Subscribed At</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {subscribers.map((subscriber) => (
                                <TableRow key={subscriber.id}>
                                    <TableCell sx={{ color: '#ddd', borderBottom: '1px solid #444' }}>
                                        {subscriber.id}
                                    </TableCell>
                                    <TableCell sx={{ color: '#ddd', borderBottom: '1px solid #444' }}>
                                        {subscriber.email}
                                    </TableCell>
                                    <TableCell sx={{ color: '#ddd', borderBottom: '1px solid #444' }}>
                                        {new Date(subscriber.subscribed_at).toLocaleString()}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                )}
            </Box>
        </Box>
    );
};

export default Subscribers;

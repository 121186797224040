import { useState, useEffect } from 'react';
import { config } from '../config';

interface Event {
    id: number;
    name: string;
    date: string;
    city: string;
    location: string;
    posterimg: string;
    description: string;
    ticketsurl: string;
    lineup: string[];
}

export const useEvents = () => {
    const [events, setEvents] = useState<Event[]>([]);
    const [loading, setLoading] = useState(true);


    useEffect(() => {
        fetchEvents();
    }, []);

    const fetchEvents = async () => {
        try {
            const response = await fetch(`${config.apiBaseUrl}/api/events`);
            if (response.ok) {
                const data = await response.json();
                setEvents(data);
            } else {
                console.error('Failed to fetch events');
            }
        } catch (error) {
            console.error('Error fetching events:', error);
        } finally {
            setLoading(false);
        }
    };

    const addEvent = async (event: Partial<Event>) => {
        try {
            const response = await fetch(`${config.apiBaseUrl}/api/events`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(event),
            });
            if (response.ok) {
                fetchEvents(); // Refresh the event list
            } else {
                console.error('Failed to add event');
            }
        } catch (error) {
            console.error('Error adding event:', error);
        }
    };

    const updateEvent = async (id: number, event: Partial<Event>) => {
        try {
            const response = await fetch(`${config.apiBaseUrl}/api/events/${id}`, {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(event),
            });
            if (response.ok) {
                fetchEvents(); // Refresh the event list
            } else {
                console.error('Failed to update event');
            }
        } catch (error) {
            console.error('Error updating event:', error);
        }
    };

    const deleteEvent = async (id: number) => {
        try {
            const response = await fetch(`${config.apiBaseUrl}/api/events/${id}`, { method: 'DELETE' });
            if (response.ok) {
                fetchEvents(); // Refresh the event list
            } else {
                console.error('Failed to delete event');
            }
        } catch (error) {
            console.error('Error deleting event:', error);
        }
    };

    return {
        events,
        loading,
        fetchEvents,
        addEvent,
        updateEvent,
        deleteEvent,
    };
};

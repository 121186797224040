import React, { useState } from 'react';
import { Box, Button, TextField, Typography } from '@mui/material';

const Contact: React.FC = () => {
    // State to handle form values
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    // Simple submit handler (can be improved with actual form submission logic)
    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        // For now, just log the form data (add form submission later)
        console.log({ name, email, message });
    };

    return (
        <Box
            component="form"
            onSubmit={handleSubmit}
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                minHeight: '100vh',  // Full screen height
                backgroundColor: 'background.default',  // Black background from theme
                textAlign: 'center',  // Center the text and content
                padding: '0 20px',  // Some padding for mobile responsiveness
            }}
        >
            {/* Contact Us Header */}
            <Typography
                variant="h1"
                sx={{
                    color: 'text.primary',  // Use white text color
                    fontWeight: 'bold',
                    mb: 4,  // Margin bottom for spacing
                    textTransform: 'uppercase',  // Uppercase for impact
                }}
            >
                Contact Us
            </Typography>

            {/* Subtitle */}
            <Typography
                variant="h6"
                sx={{
                    color: 'text.secondary',  // Use secondary text color (lighter gray)
                    mb: 6,  // Spacing below the subtitle
                }}
            >
                We'd love to hear from you. Feel free to reach out with any inquiries.
            </Typography>

            {/* Name Input */}
            <TextField
                label="Name"
                variant="outlined"
                fullWidth
                required
                sx={{
                    maxWidth: '600px',  // Set max width for the input
                    mb: 3,  // Space between inputs
                    input: { color: 'white' },  // White text inside the input
                    label: { color: 'white' },  // White label
                    fieldset: { borderColor: 'white' },  // White border
                    '& .MuiOutlinedInput-root:hover fieldset': {
                        borderColor: 'white',  // White border on hover
                    },
                }}
                value={name}
                onChange={(e) => setName(e.target.value)}
            />

            {/* Email Input */}
            <TextField
                label="Email"
                variant="outlined"
                fullWidth
                required
                sx={{
                    maxWidth: '600px',
                    mb: 3,
                    input: { color: 'white' },
                    label: { color: 'white' },
                    fieldset: { borderColor: 'white' },
                    '& .MuiOutlinedInput-root:hover fieldset': {
                        borderColor: 'white',
                    },
                }}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
            />

            {/* Message Input */}
            <TextField
                label="Message"
                variant="outlined"
                fullWidth
                required
                multiline
                rows={4}
                sx={{
                    maxWidth: '600px',
                    mb: 4,
                    input: { color: 'white' },
                    label: { color: 'white' },
                    fieldset: { borderColor: 'white' },
                    '& .MuiOutlinedInput-root:hover fieldset': {
                        borderColor: 'white',
                    },
                }}
                value={message}
                onChange={(e) => setMessage(e.target.value)}
            />

            {/* Submit Button */}
            <Button
                type="submit"
                variant="outlined"
                sx={{
                    color: 'white',  // White text
                    borderColor: 'white',  // White border
                    textTransform: 'uppercase',  // Keep button text uppercase
                    '&:hover': {
                        backgroundColor: 'white',  // White background on hover
                        color: 'black',  // Black text on hover
                    },
                }}
            >
                Submit
            </Button>
        </Box>
    );
};

export default Contact;

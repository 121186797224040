import React from 'react';
import { Typography, Box } from '@mui/material';
import nightclubImageBW from '../assets/party-photo-4.png';
import {config} from "../config"; // Adjust the path to where the image is stored

const Intro: React.FC = () => {
    return (
        <Box
            sx={{
                backgroundImage: `url(${nightclubImageBW})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                color: 'white',
                textAlign: 'center',
                py: 10,
                minHeight: '500px', // Adjust height as needed
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-end', // Align text box to the bottom
                alignItems: 'center',
                position: 'relative',
            }}
        >
            <Box
                sx={{
                    backgroundColor: 'rgba(0, 0, 0, 0.7)', // Slightly darker semi-transparent black
                    width: '100vw', // Full width of the viewport
                    px: 3, // Padding on the left and right for inner content
                    py: 2, // Padding on the top and bottom
                    borderRadius: 0, // No rounded corners to cover full width
                    position: 'absolute',
                    bottom: 0, // Position it at the bottom of the container
                    left: 0, // Align it to the left edge
                    right: 0, // Align it to the right edge
                    zIndex: 1, // Ensure it stays on top of the background
                }}
            >
                <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                    {config.appDescription}
                </Typography>
            </Box>
        </Box>
    );
};

export default Intro;
